import Config from "@/data/Config"
import axios from "axios"

const apiUrl = {
    login: "/auth/login",
    editUserInfo: "/user/edit",
    getInfo: "/user/info",
    getQuestion: "/answer/get-question",
    userAnswer: "/answer/do-answer",
    award: "/answer/award",
    serial: "/answer/serial",
    nextStage: "/answer/next-stage",
    questionList: "/answer/question-list",


}
export function QuestionList(data: { page: number, pageSize: number, sort: { columne: string, sort: number }[] }) {
    return axios.post(Config.BaseURL + apiUrl.questionList, data, { headers: { "X-Token": Config.UserInfo.token } })
}
export function Login(data) {
    return axios.post(Config.BaseURL + apiUrl.login, data)
}
export function EditUserInfo(data) {
    return axios.post(Config.BaseURL + apiUrl.editUserInfo, data, { headers: { "X-Token": Config.UserInfo.token } })
}
export function NextStage() {
    return axios.post(Config.BaseURL + apiUrl.nextStage, null, { headers: { "X-Token": Config.UserInfo.token } })
}
export function GetInfo(data) {
    return axios.get(Config.BaseURL + apiUrl.getInfo, { headers: { "X-Token": Config.UserInfo.token } })
}
export function GetSerial() {
    return axios.get(Config.BaseURL + apiUrl.serial, { headers: { "X-Token": Config.UserInfo.token } })
}
export function GetQuestion(id: number) {
    return axios.get(Config.BaseURL + apiUrl.getQuestion + "?id=" + id, { headers: { "X-Token": Config.UserInfo.token } })
}
export function UserAnswer(data: { answer: string[], question_id: number }) {
    return axios.post(Config.BaseURL + apiUrl.userAnswer, data, { headers: { "X-Token": Config.UserInfo.token } })
}
export function Award() {
    return axios.get(Config.BaseURL + apiUrl.award, { headers: { "X-Token": Config.UserInfo.token } })
}