import { connect, signMessage, getAccount, getConnectors,watchConnections, watchClient, getBalance, disconnect, watchAccount } from '@wagmi/core'
import { injected } from '@wagmi/connectors'
import { config } from './config'

export async function Content(sign) {
    return new Promise<{ addr: string, sign: string }>(async (resolve, reject) => {
        connect(config, { connector: injected() }).then(r => {
            signMessage(config, { account: r.accounts[0], message: String(sign) }).then(rr => {
                resolve({ addr: r.accounts[0], sign: rr })
            }).catch(e => {
                reject(e)
            })
        }).catch(e => {
            reject(e)
        })

    })

}
export function GetAccounts() {
    return getAccount(config)
}
export function GetConnectors() {
    return getConnectors(config)
}
export function WatchConnections(cb: Function) {
    return watchConnections(config, {
        onChange(accounts,c) {
          
            cb(accounts,c)
        }
    })
}
export function WatchClient(cb: Function) {
    return watchClient(config, {
        onChange(client) {
            cb(client)
        }
    })
}
export function WatchAccount(cb: Function) {
    return watchAccount(config, {
        onChange(client) {
            cb(client)
        }
    })
} export async function SignMessage(addr, sign) {
    return signMessage(config, { account: addr, message: String(sign) })
}
export async function GetBalance(addr) {
    return getBalance(config, { address: addr })
}
export async function DisConnect() {
    const account = getAccount(config)
    account.connector.disconnect()
    return disconnect(config)
}