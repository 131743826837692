export interface UserInfoStruct {
    token: string,
    user: {
        name: string,
        agent_id: string,
        agent_url: string
    }
}
export enum Items {
    UserInfo = 1001,
    Token,
    NowPage,
    NowQData,
    ShowRight,
    WalletAddress,
}
export enum QuestionType {
    单选 = "single-choose",
    多选 = "multiple-choose",
    判断 = " true-false"
}
export interface QuestionStruct {
    active: boolean,
    answer: number[],
    choice: { index: string, content: string }[],
    createdAt: string,
    id: number,
    question: string,
    stageIndex:number,
    type: QuestionType
}
export default class Config {
    // public static BaseURL = "http://localhost:8088/api/v1"
    public static BaseURL = "http://43.241.72.245:8088/api/v1"

    public static UserInfo: UserInfoStruct
    public static Login = false
    public static WalletAddress = ""
    public static Version = "1.0.0"
}
