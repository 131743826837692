import { createRouter, createWebHashHistory, RouteRecordRaw, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import { Items } from '@/data/Config'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/list',
    name: 'list',
    component: () => import(/* webpackChunkName: "about" */ '../views/QList.vue')
  },
  {
    path: '/answer',
    name: 'answer',
    component: () => import(/* webpackChunkName: "about" */ '../views/APage.vue')
  },
  {
    path: '/result',
    name: 'result',
    component: () => import(/* webpackChunkName: "about" */ '../views/Result.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeResolve((to, from, next) => {
  if (to && to.name && to.name != "home") {
    localStorage.setItem(Items.NowPage + "", "/"+to.name.toString())

  }
  next()

})
export default router
