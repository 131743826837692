import { http, createConfig } from '@wagmi/core'
import { mainnet, sepolia } from '@wagmi/core/chains'
import { walletConnect } from '@wagmi/connectors'
const d = {
  chains: [mainnet, sepolia],
  // connectors: [
  //   walletConnect({
  //     projectId: '3fcc6bba6f1de962d911bb5b5c3dba68',
  //   }),
  // ],
  transports: {
    [mainnet.id]: http(),
    [sepolia.id]: http(),
  }
}
let cc: any = createConfig
export const config = cc(d)